import { subMinutes } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';

import { PromiseLimit, UUID } from '@dametis/core';

import DaLineSeries from 'classes/DaCharts/DaLineSeries';
import { getCalculationUnit } from 'functions/getCalculationUnit';
import { unsubscribeFromDataRooms } from 'functions/socketIo';
import { TypedThunk } from 'store';
import { livechartAddVariable, livechartDeleteVariable, livechartUpdateChart, livechartUpdateVariable } from 'store/slices/playground';
import { ILiveChart, ILiveChartProps, ILiveVariable, IsLiveChartTab } from 'types';

import { createChart, setDaChart } from '../Chart';
import { createPeriod } from '../TimeRange';
import { chartToPng, getStoreTab } from '../shared';

import { exportLiveVariable, getLiveVariableData } from './LiveVariable';

export const addLiveVariableToDaChart =
  (tabUuid: UUID, variable: ILiveVariable): TypedThunk<Promise<void>> =>
  async (dispatch, getState) => {
    try {
      const { withYAxisVisible } = getState().playground;
      const tab = dispatch(getStoreTab(tabUuid));
      if (!IsLiveChartTab(tab)) return;

      const yAxis = tab.chart.daChart.addAxis({ labelsEnabled: withYAxisVisible });
      const daSeries = tab.chart.daChart.addSeries({
        name: variable.name,
        color: variable.color,
        unit: getCalculationUnit(variable.expression),
        selectedUnit: variable.unit,
        style: {},
        yAxis,
      }) as DaLineSeries;
      dispatch(livechartUpdateVariable({ tabUuid: tab.uuid, variableUuid: variable.uuid, options: { daSeries } }));
      await dispatch(getLiveVariableData(tab.uuid, variable.uuid));
      if (variable.min !== undefined || variable.max !== undefined) {
        daSeries.yAxis.setExtremes(variable.min, variable.max);
      }
    } catch (err) {
      console.error(err);
    }
  };

export const createLiveChart = ({ uuid = uuidv4(), daChart = null, variables = [] }: ILiveChartProps = {}): ILiveChart => {
  const chart = createChart({ uuid, daChart }) as ILiveChart;
  return { ...chart, variables, customTimeRange: createPeriod({ from: subMinutes(new Date(), 5) }) };
};

export const exportLiveChart = async (chart: ILiveChart): Promise<ILiveChart> => {
  if (!chart) return null;
  const { variables, daChart } = chart;
  const preview = await chartToPng(daChart);
  return { ...chart, preview, daChart: null, variables: variables.map(exportLiveVariable), customTimeRange: null };
};

export const getLiveChartData =
  (tabUuid: UUID): TypedThunk<Promise<void>> =>
  async dispatch => {
    const tab = dispatch(getStoreTab(tabUuid));
    if (!IsLiveChartTab(tab)) return;
    await PromiseLimit.do(tab.chart?.variables, v => dispatch(getLiveVariableData(tabUuid, v.uuid)), { settle: true });
  };

// ACTIONS

export const addLiveVariableToLiveChart =
  (tabUuid: UUID, variable: ILiveVariable): TypedThunk<Promise<void>> =>
  async dispatch => {
    const tab = dispatch(getStoreTab(tabUuid));
    if (!IsLiveChartTab(tab)) return;
    dispatch(livechartAddVariable(variable));
    await dispatch(addLiveVariableToDaChart(tab.uuid, variable));
    tab.chart.daChart.redraw();
  };

export const deleteLiveVariableFromLiveChart =
  (chart: ILiveChart, variable: ILiveVariable): TypedThunk<Promise<void>> =>
  async dispatch => {
    chart.daChart.removeSeries(variable.daSeries);
    await unsubscribeFromDataRooms([{ id: variable.socketRoomId, listener: variable.socketHandler }]);
    dispatch(livechartDeleteVariable(variable));
  };

export const setDaChartToLiveChart =
  (tabUuid: UUID, container: HTMLDivElement): TypedThunk<Promise<void>> =>
  async dispatch => {
    const tab = dispatch(getStoreTab(tabUuid));
    if (!IsLiveChartTab(tab)) return;
    const daChart = dispatch(setDaChart(tab.chart, container, { type: 'line', periodZoom: false, legend: false, height: null }));
    await PromiseLimit.do(tab.chart.variables, v => dispatch(addLiveVariableToDaChart(tab.uuid, v)), { settle: true });
    daChart.toggleZoomType();
    daChart.redraw();
  };

export const updateLiveChart =
  (tabUuid: UUID, options: ILiveChartProps): TypedThunk<void> =>
  dispatch => {
    // const tab = dispatch(getStoreTab(tabUuid));
    // if (!IsLiveChartTab(tab)) return;
    dispatch(livechartUpdateChart({ tabUuid, options }));
    // if (options.customTimeRange?.from instanceof Date) {
    //   chart?.daChart.xAxis[0]?.setExtremes(options.customTimeRange.from.getTime());
    // }
    // if (options.customTimeRange?.to instanceof Date) {
    //   chart?.daChart.xAxis[0]?.setExtremes(chart?.daChart.xAxis[0]?.getExtremes().userMin, options.customTimeRange.to.getTime());
    // }
  };

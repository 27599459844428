import { v4 as uuidv4 } from 'uuid';

import { PromiseLimit, UUID } from '@dametis/core';

import DaLineSeries from 'classes/DaCharts/DaLineSeries';
import { TypedThunk } from 'store';
import { tychartAddGroup, tychartDeleteVariable, tychartUpdateGroup, tychartUpdateGroups } from 'store/slices/playground';
import { theme } from 'theme';

import { ITab, ITyChart, ITyGroup, ITyGroupProps, ITyVariable, IsTyChartTab } from '../../types';
import { getStoreTab } from '../shared';

import { addTyVariableToDaChart } from './TyChart';
import { exportTyVariable, getStoreTyGroup, updateTyVariable } from './TyVariable';

export const createTyGroup = ({
  uuid = uuidv4(),
  name = '',
  unit = '',
  min = null,
  max = null,
  yAxis = null,
  variables = [],
  hidden = false,
}: Partial<ITyGroup> = {}): ITyGroup => ({
  uuid,
  name: name?.length ? name : unit,
  unit,
  min,
  max,
  yAxis,
  variables: variables.map(v => ({ ...v, groupUuid: uuid })),
  hidden,
});

export const exportTyGroup = (group: ITyGroup): ITyGroup => {
  if (!group) return null;
  return { ...group, yAxis: null, variables: group.variables.map(exportTyVariable) };
};

export const findTyGroup = (groups: ITyGroup[], variable: ITyVariable): ITyGroup =>
  groups.find(group => variable.unit && group.unit === variable.unit);

// ACTIONS

export const addTyVariableToNewTyGroup =
  (tabUuid: UUID, currentVariable: ITyVariable, type?: 'line' | 'bar'): TypedThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const tab = dispatch(getStoreTab(tabUuid));
    if (IsTyChartTab(tab)) {
      const { withYAxisVisible } = getState().playground;
      const { customPeriod } = tab;
      const yAxis = tab.chart.daChart.addAxis({
        color: theme.palette.grey[900],
        unit: currentVariable.unit,
        id: uuidv4(),
        pretty: false,
        hidden: false,
        min: null,
        max: null,
        labelsEnabled: withYAxisVisible,
      });
      const groupUuid = uuidv4();
      const variable: ITyVariable = { ...currentVariable, groupUuid, type: type ?? (tab.chart.isBarChart ? 'bar' : 'line') };
      const newGroup = createTyGroup({ uuid: groupUuid, unit: variable.unit, yAxis, variables: [variable] });
      dispatch(tychartAddGroup({ tabUuid, group: newGroup }));
      if (customPeriod && customPeriod.length) {
        await PromiseLimit.do(
          customPeriod,
          async cP => {
            await dispatch(
              addTyVariableToDaChart({
                tabUuid,
                variable,
                yAxis,
                xAxis: cP.xAxis,
                uuid: variable.uuid,
                customPeriod: cP,
              }),
            );
          },
          { settle: true },
        );
      } else {
        await dispatch(addTyVariableToDaChart({ tabUuid, variable, yAxis }));
      }
      tab.chart.daChart.redraw();
    }
  };

export const addTyVariableToTyGroup =
  (tabUuid: UUID, groupUuid: UUID, currentVariable: ITyVariable, index?: number, type?: 'line' | 'bar'): TypedThunk<Promise<void>> =>
  async dispatch => {
    const tab = dispatch(getStoreTab(tabUuid));
    if (!IsTyChartTab(tab)) return;
    const { customPeriod } = tab;
    const group = dispatch(getStoreTyGroup(groupUuid, tabUuid));
    const insertIndex = index ?? group.variables.length;
    const newVariables = [];
    let inserted = false;
    const variable: ITyVariable = { ...currentVariable, groupUuid: group.uuid, type: type ?? (tab.chart.isBarChart ? 'bar' : 'line') };
    for (let i = 0; i < group.variables.length; i += 1) {
      if (i === insertIndex) {
        inserted = true;
        newVariables.push(variable);
      }
      newVariables.push(group.variables[i]);
    }
    if (!inserted) {
      newVariables.push(variable);
    }
    const options = {
      variables: newVariables,
    };
    dispatch(tychartUpdateGroup({ tabUuid, groupUuid, options }));
    if (!variable.hidden) {
      group.yAxis.update(
        {
          visible: true,
        },
        false,
      );
    }
    if (customPeriod && customPeriod.length) {
      await PromiseLimit.do(
        customPeriod,
        async cP => {
          await dispatch(
            addTyVariableToDaChart({
              tabUuid,
              variable: { ...variable, dashStyle: cP.dashStyle },
              yAxis: group.yAxis,
              xAxis: cP.xAxis,
              uuid: variable.uuid,
              customPeriod: cP,
            }),
          );
        },
        { settle: true },
      );
    } else {
      await dispatch(
        addTyVariableToDaChart({
          tabUuid,
          variable,
          yAxis: group.yAxis,
        }),
      );
    }

    tab.chart.daChart.redraw();
  };

export const updateTyGroup =
  (tabUuid: UUID, groupUuid: UUID, options: ITyGroupProps): TypedThunk<void> =>
  dispatch => {
    const tab = dispatch(getStoreTab(tabUuid));
    if (!IsTyChartTab(tab)) return;
    const group = dispatch(getStoreTyGroup(groupUuid, tabUuid));
    dispatch(tychartUpdateGroup({ tabUuid, groupUuid, options }));
    if (options.min !== undefined || options.max !== undefined) {
      group.yAxis.setExtremes(options.min, options.max);
    }
    if (options.hidden !== undefined) {
      group.variables.forEach(variable => {
        variable.daSeries.yAxis.series.forEach(s => {
          (s as DaLineSeries).setVisible(!options.hidden && !variable.hidden);
        });
      });
      group.yAxis.update({ visible: !options.hidden }, false);
    }
    if (options.unit !== undefined) {
      group.variables.forEach(variable => dispatch(updateTyVariable(tabUuid, variable.uuid, { unit: options.unit })));
    }
    tab.chart.daChart.redraw();
  };

export const updateTyGroups =
  (tabUuid: UUID, groups: ITyGroup[]): TypedThunk<void> =>
  dispatch => {
    dispatch(tychartUpdateGroups({ tabUuid, groups }));
  };

export const deleteTyVariableFromDaChart = (tab: ITab<ITyChart>, group: ITyGroup, variable: ITyVariable) => {
  const seriesToRemove = group.yAxis.series.filter(s => (s as DaLineSeries).uuid === variable.uuid && s);
  if (seriesToRemove.length) {
    seriesToRemove.forEach(s => s.remove());
  } else {
    tab.chart.daChart.removeSeries(variable.daSeries);
  }
};

export const deleteTyVariableFromTyGroup =
  (tabUuid: UUID, groupUuid: UUID, variable: ITyVariable): TypedThunk<void> =>
  dispatch => {
    const tab = dispatch(getStoreTab(tabUuid));
    if (IsTyChartTab(tab)) {
      const group = tab.chart.groups.find(g => g.uuid === groupUuid);
      deleteTyVariableFromDaChart(tab, group, variable);
      dispatch(tychartDeleteVariable({ tabUuid, group, variable }));
      tab.chart.daChart.redraw();
    }
  };

// export const deleteTyGroupFromTyChart =
// (chart: ITyChart, group: ITyGroup): ThunkAction<void, RootState, unknown, DrawerAction<ITyVariable>> =>
// dispatch => {
//   chart.daChart.removeSeries(variable.daSeries, group.variables.length <= 1);
//   dispatch(tychartDeleteVariable({ tabUuid, group, variable }));
// };

import { intervalToDuration, sub } from 'date-fns';
import type { Axis } from 'highcharts';
import { v4 as uuidv4 } from 'uuid';

import { AUTO_GROUPBY, IsDataVariable, Period, PeriodWithDashStyle, PromiseLimit, TabType, UUID } from '@dametis/core';
import { getSingleVariableCalculation } from '@dametis/mathjs';

import { DaAxis } from 'classes/DaCharts/DaAxis';
import { DaChart } from 'classes/DaCharts/DaChart';
import DaLineSeries from 'classes/DaCharts/DaLineSeries';
import { getNextDashStyle } from 'functions/getNextDashStyle';
import { TypedThunk } from 'store';
import { addAndSelectTab, updateTab } from 'store/actions/playground';
import { tychartUpdateChart, tychartUpdateGroup, tychartUpdateVariable } from 'store/slices/playground';
import { theme } from 'theme';

import { IPeriodWithDashStyle, ITyChart, ITyChartProps, ITyVariable, IsTyChartTab } from '../../types';
import { createChart, setDaChart } from '../Chart';
import { createTab } from '../Tab';
import { chartToPng, getStoreTab } from '../shared';

import { createTyGroup, exportTyGroup } from './TyGroup';
import { getStoreTyGroup, getTyVariableData } from './TyVariable';

export const addTyVariableToDaChart =
  ({
    tabUuid,
    variable,
    yAxis,
    xAxis = undefined,
    customPeriod = undefined,
    uuid = undefined,
  }: {
    tabUuid: UUID;
    variable: ITyVariable;
    yAxis: DaAxis;
    xAxis?: Axis;
    customPeriod?: PeriodWithDashStyle;
    uuid?: string;
  }): TypedThunk<Promise<void>> =>
  async dispatch => {
    try {
      const tab = dispatch(getStoreTab(tabUuid));
      if (!IsTyChartTab(tab)) return;
      const singleVariable = getSingleVariableCalculation(variable.expression);
      const computation = singleVariable || undefined;
      const group = dispatch(getStoreTyGroup(variable.groupUuid, tab.uuid));
      const type = variable?.type ?? (tab.chart.isBarChart ? 'bar' : 'line');
      const daSeries = tab.chart.daChart.addSeries({
        name: variable.name,
        color: variable.color,
        unit: variable.unit,
        style: {
          type,
          dashStyle: variable.dashStyle ?? customPeriod?.dashStyle,
        },
        pretty: false,
        hidden: group?.hidden ? group.hidden : variable.hidden,
        yAxis,
        xAxis,
        uuid: uuid ?? (IsDataVariable(computation) ? computation.variableUuid : undefined),
        format: variable.format,
        zIndex: type === 'line' ? 2 : 1,
      }) as DaLineSeries;
      dispatch(tychartUpdateVariable({ tabUuid, groupUuid: variable.groupUuid, variableUuid: variable.uuid, options: { daSeries } }));
      await dispatch(getTyVariableData(variable, tab, daSeries, customPeriod));
    } catch (err) {
      console.error(err);
    }
  };

export const createTyChart = ({
  uuid = uuidv4(),
  daChart = null,
  groups = [],
  customTimeRange = undefined,
  isBatchChart = undefined,
  isBarChart = undefined,
  groupBy = AUTO_GROUPBY,
}: ITyChartProps = {}): ITyChart => {
  const chart = createChart({ uuid, daChart });
  return { ...chart, groups: groups.map(group => createTyGroup(group)), customTimeRange, isBatchChart, isBarChart, groupBy };
};

export const exportTyChart = async (chart: ITyChart): Promise<ITyChart> => {
  if (!chart) return null;
  const { groups, daChart } = chart;
  const preview = await chartToPng(daChart);
  return { ...chart, preview, daChart: null, groups: groups.map(exportTyGroup) };
};

export const getTyChartData =
  (tabUuid: UUID): TypedThunk<Promise<void>> =>
  async dispatch => {
    const tab = dispatch(getStoreTab(tabUuid));
    if (!IsTyChartTab(tab)) return;
    const variables =
      tab.chart?.groups?.reduce<ITyVariable[]>(
        (a, v) => [...a, ...v.variables.map(variable => ({ ...variable, groupUuid: v.uuid }))],
        [],
      ) ?? [];
    await PromiseLimit.do(variables, v => dispatch(getTyVariableData(v, tab, v.daSeries)), { settle: true });
  };

// ACTIONS

export const updateTyChart =
  (tabUuid: UUID, options: ITyChartProps): TypedThunk<Promise<void>> =>
  async dispatch => {
    const tab = dispatch(getStoreTab(tabUuid));
    if (!IsTyChartTab(tab)) return;
    dispatch(tychartUpdateChart({ tabUuid, options }));
    if (options.groupBy !== undefined) {
      await dispatch(getTyChartData(tabUuid));
    }
    tab.chart.daChart.redraw();
  };

export const setDaChartToTyChart =
  (tabUuid: UUID, container: HTMLDivElement): TypedThunk<Promise<DaChart>> =>
  async (dispatch, getState) => {
    const tab = dispatch(getStoreTab(tabUuid));
    if (!IsTyChartTab(tab)) return null;
    const { withYAxisVisible } = getState().playground;
    const daChart = dispatch(setDaChart(tab.chart, container, { type: 'line', legend: false, periodZoom: true, height: null }));
    let customPeriodsWithAxis: IPeriodWithDashStyle[];
    if (tab.customPeriod) {
      daChart.toggleZoomType();
      if (tab.chart.isBatchChart || tab.customPeriod.length > 1) {
        const from = new Date(tab.customPeriod[0].from).getTime();
        daChart.setToDurationAxis(from);
      }
      customPeriodsWithAxis = dispatch(setCustomPeriodsOnTyChart(tabUuid));
    }
    await PromiseLimit.do(
      tab.chart.groups,
      async group => {
        const yAxis = daChart.addAxis({
          color: theme.palette.grey[900],
          unit: group.unit,
          id: uuidv4(),
          pretty: false,
          hidden: group.hidden,
          min: group.min,
          max: group.max,
          labelsEnabled: withYAxisVisible,
        });
        dispatch(tychartUpdateGroup({ tabUuid, groupUuid: group.uuid, options: { yAxis } }));
        await PromiseLimit.do(
          group.variables,
          async v => {
            if (customPeriodsWithAxis) {
              await PromiseLimit.do(
                customPeriodsWithAxis,
                async cP => {
                  await dispatch(
                    addTyVariableToDaChart({
                      tabUuid,
                      variable: { ...v, dashStyle: cP.dashStyle, groupUuid: group.uuid },
                      yAxis,
                      xAxis: cP.xAxis ?? daChart.xAxis[0],
                      customPeriod: cP,
                      uuid: v.uuid,
                    }),
                  );
                },
                { settle: true },
              );
              if (tab.chart.isBatchChart) {
                if (customPeriodsWithAxis[0]) {
                  const batchFrom = new Date(customPeriodsWithAxis[0]?.from).getTime();
                  const batchTo = new Date(customPeriodsWithAxis[0]?.to).getTime();
                  daChart.drawBatch(
                    batchFrom,
                    customPeriodsWithAxis?.length > 1 ? Infinity : batchTo,
                    typeof tab.chart.isBatchChart === 'number' ? tab.chart.isBatchChart : undefined,
                  );
                }
              }
            } else {
              await dispatch(
                addTyVariableToDaChart({
                  tabUuid,
                  variable: v,
                  yAxis,
                }),
              );
            }
          },
          { settle: true },
        );
      },
      { settle: true },
    );
    daChart.redraw();
    return daChart;
  };

export const getGreatestDiff = (customPeriods: IPeriodWithDashStyle[]): number => {
  let greatestDiff = 0;
  customPeriods.forEach(cP => {
    const actualDiff = new Date(cP.to).getTime() - new Date(cP.from).getTime();
    if (actualDiff > greatestDiff) greatestDiff = actualDiff;
  });
  return greatestDiff;
};

export const setCustomPeriodsOnTyChart =
  (tabUuid: UUID): TypedThunk<IPeriodWithDashStyle[]> =>
  dispatch => {
    const tab = dispatch(getStoreTab(tabUuid));
    if (!IsTyChartTab(tab)) return null;
    const customPeriodsWithAxis = tab.customPeriod.map(
      (cP, index) =>
        ({
          ...cP,
          xAxis: tab.chart.daChart.xAxis[index] ?? tab.chart.daChart.addXAxis(),
        }) as IPeriodWithDashStyle,
    );
    dispatch(updateTab(tabUuid, { customPeriod: customPeriodsWithAxis }));
    return customPeriodsWithAxis;
  };

export const addCustomPeriodToTyChart =
  (tabUuid: UUID): TypedThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const tab = dispatch(getStoreTab(tabUuid));
    if (!IsTyChartTab(tab)) return;
    const { period } = getState().period.present;
    const newPeriods = tab.customPeriod ? [...tab.customPeriod] : [];
    const dashStyle = getNextDashStyle(tab.customPeriod ?? []);
    let { from } = period;
    let { to } = period;
    if (!newPeriods.length) {
      tab.chart.daChart.toggleZoomType();
    }
    if (newPeriods.length) {
      const duration = intervalToDuration({ start: new Date(newPeriods[0].from), end: new Date(newPeriods[0].to) });
      to = sub(new Date(newPeriods[newPeriods.length - 1].from), { seconds: 1 });
      from = sub(new Date(to), duration);
    }
    const newPeriod = {
      ...new Period({
        from,
        to,
      }),
      dashStyle,
    } as PeriodWithDashStyle;
    const newXAxis =
      tab.customPeriod?.length > 0 ? tab.chart.daChart.addXAxis() : (tab.chart.daChart.xAxis[0] ?? tab.chart.daChart.addXAxis());
    newPeriods.push({ ...newPeriod, xAxis: newXAxis } as IPeriodWithDashStyle);
    const seriesToDelete = tab.chart.daChart.series.map(s => s);
    await PromiseLimit.do(
      tab.chart.groups,
      async group => {
        await PromiseLimit.do(
          group.variables,
          async v => {
            await dispatch(
              addTyVariableToDaChart({
                tabUuid,
                variable: { ...v, dashStyle },
                yAxis: group.yAxis,
                xAxis: newXAxis,
                uuid: v.uuid,
                customPeriod: newPeriod,
              }),
            );
          },
          { settle: true },
        );
      },
      { settle: true },
    );
    if (newPeriods.length === 1) {
      seriesToDelete.forEach(s => s.remove());
    } else if (newPeriods.length === 2) {
      tab.chart.daChart.setToDurationAxis(new Date(newPeriods[0].from).getTime());
    }
    tab.chart.daChart.redraw();
    dispatch(updateTab(tabUuid, { customPeriod: newPeriods }));
  };

export const updateCustomPeriodOnTyChart =
  (tabUuid: UUID, index: number, period: { from: Date; to: Date }): TypedThunk<Promise<void>> =>
  async dispatch => {
    const tab = dispatch(getStoreTab(tabUuid));
    if (!IsTyChartTab(tab)) return;
    const newPeriods = [...tab.customPeriod];
    const updatedPeriod = { ...tab.customPeriod[index], from: period.from, to: period.to } as PeriodWithDashStyle;
    newPeriods.splice(index, 1, updatedPeriod);
    dispatch(updateTab(tabUuid, { customPeriod: newPeriods }));
    await PromiseLimit.do(
      tab.chart.groups,
      async group => {
        await PromiseLimit.do(
          group.variables,
          async v => {
            await dispatch(getTyVariableData(v, tab, undefined, updatedPeriod));
          },
          { settle: true },
        );
      },
      { settle: true },
    );
    tab.chart.daChart.redraw();
  };

export const deleteCustomPeriodOfTyChart =
  (tabUuid: UUID, index: number): TypedThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const tab = dispatch(getStoreTab(tabUuid));
    if (!IsTyChartTab(tab)) return;
    const { withYAxisVisible } = getState().playground;
    const { daChart } = tab.chart;
    const newPeriods = tab.customPeriod ? [...tab.customPeriod] : [];
    newPeriods.splice(index, 1);
    if (tab.customPeriod?.length > 1) tab.customPeriod[index].xAxis.remove();
    if (newPeriods?.length === 1) {
      daChart.setToTimeAxis();
    }
    if (!newPeriods?.length) {
      daChart.toggleZoomType();
      daChart.removeAllSeries();
      tab.chart.daChart.resetTooltip();
      await PromiseLimit.do(
        tab.chart.groups,
        async group => {
          const yAxis = daChart.addAxis({
            color: theme.palette.grey[900],
            unit: group.unit,
            id: uuidv4(),
            pretty: false,
            hidden: false,
            min: group.min,
            max: group.max,
            labelsEnabled: withYAxisVisible,
          });
          dispatch(tychartUpdateGroup({ tabUuid, groupUuid: group.uuid, options: { yAxis } }));
          await PromiseLimit.do(
            group.variables,
            async v => {
              await dispatch(
                addTyVariableToDaChart({
                  tabUuid,
                  variable: { ...v, groupUuid: group.uuid },
                  yAxis,
                  xAxis: daChart.xAxis[0],
                }),
              );
            },
            { settle: true },
          );
        },
        { settle: true },
      );
      daChart.redraw();
    }
    dispatch(updateTab(tabUuid, { customPeriod: newPeriods?.length ? newPeriods : undefined }));
  };

export const openTyTabfromTyChart =
  (tabUuid: UUID): TypedThunk<void> =>
  dispatch => {
    const storeTab = dispatch(getStoreTab(tabUuid));
    if (!IsTyChartTab(storeTab)) return;
    const tyChart = createTyChart({
      groups: storeTab.chart.groups,
    });
    const newTab = createTab({
      type: TabType.TY_CHART,
      chart: tyChart,
    });
    dispatch(addAndSelectTab(newTab));
  };
